import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"

export default function Contact() {
  return (
    <div style={{ color: `teal` }}>
      <Link to="/">Acasa</Link>
      <Header headerText="Bun venit pe Contact page" />
      <p>Send us a message!</p>
    </div>
  )
}